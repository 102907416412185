import { Component } from '@angular/core';;
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../shared/http.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent
{
    public contactForm: FormGroup;
    public inRequest: boolean = false;
    public showFeedback: boolean = false;
    public feedbackError: boolean = false;

    constructor(private httpService: HttpService)
    {
        this.contactForm = new FormGroup({
            firstName: new FormControl(null, Validators.required),
            lastName: new FormControl(null, Validators.required),
            companyName: new FormControl(null),
            email: new FormControl(null, [Validators.required, Validators.email]),
            message: new FormControl(null, Validators.required)
        });
    }

    public isContactFormValid(): boolean
    {
        return this.contactForm.valid;
    }

    public validateFeedbackClose(): void
    {
        this.showFeedback = false;
        this.feedbackError = false;
        if (!this.feedbackError)
        {
            this.contactForm.reset();
        }
    }

    public submitContact(): void
    {
        if (this.isContactFormValid())
        {
            const contactData: object =
            {
                name: `${this.contactForm.value.firstName} ${this.contactForm.value.lastName}`,
                companyName: this.contactForm.value.companyName,
                email: this.contactForm.value.email,
                message: this.contactForm.value.message
            }

            this.inRequest = true;
            this.httpService.sendMessage(contactData).subscribe(((data: object) =>
            {
                this.showFeedback = true;
                this.feedbackError = data['error'];
                this.inRequest = false;
            }), ()=>
            {
                this.showFeedback = true;
                this.feedbackError = true;
                this.inRequest = false;
            });
        }
    }
}
