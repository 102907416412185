import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-custom-card',
    templateUrl: './custom-card.component.html',
    styleUrls: ['./custom-card.component.scss']
})
export class CustomCardComponent implements OnChanges
{
	@Input() public content: string = '';
    public contentDate: string;
    @Input() public image: string = '';
    @Input() public autoHeight: boolean = false;
    @Input() public haveDate: boolean = false;
    public opened: boolean = false;

    public toggleCard(): void
    {
        this.opened = !this.opened;
    }

    public ngOnChanges(): void
    {
        if (this.haveDate)
        {
            this.setDateFromContent();
        }
    }
    
    private setDateFromContent(): void
	{
        let contentDate: string = this.content.slice(0, 10);
		if (Date.parse(contentDate) > 0)
		{
            this.contentDate = contentDate;
			this.content = this.content.slice(13);
		}
	}
}
