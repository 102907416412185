import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from './shared/filter.service';
import { HttpService } from './shared/http.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{

    public isUserLogged: boolean = false;
    public showHeaderOptions: boolean = true;
    public showWarning: boolean = false;
    public userData: object = {
        name: null,
        address: null,
        country: null,
        phone: null,
        website: null,
        company_email: null,
        slogan: null,
        industry_tags: null,
        type: null,
        logo: null,
        technology_challenges: [''],
        technology_offers: [''],
        social_media: ['']
    };
    public constructor(private translate: TranslateService, private router: Router, private httpService: HttpService, private filterService: FilterService) {}

    public ngOnInit(): void
    {
        this.translate.addLangs(['en', 'pt']);
        const language: string = window.localStorage.getItem('language');

        if (language && language !== '')
        {
            this.translate.setDefaultLang(language);
            this.translate.use(language);
        }
        else
        {
            this.translate.setDefaultLang('pt');
            this.translate.use('pt');
        }

        this.filterService.profileUpdated.subscribe((data: object) =>
        {
            if (data)
            {
                this.setShowWarning(data);
            }
        });

        this.router.events.subscribe((data: RouterEvent) =>
        {
            if (data instanceof NavigationEnd)
            {
                window.scroll(0, 0);
                this.httpService.isUserLogged().subscribe((data: boolean) =>
                {
                    this.isUserLogged = data;
                    if (this.isUserLogged)
                    {
                        this.getUserData();
                    }
                })
            }
        });
    }

    public setShowWarning(data: object): void
    {
        const userKeys: string[] = Object.keys(this.userData);
                
        this.showWarning = userKeys.some((userKey: string) =>
        {
            return data[userKey] == null || (data[userKey] && data[userKey].length) == 0;
        });
    }

    public getUserData(): void
    {
        this.httpService.getAccountData().subscribe((data: object) =>
        {
            if (data)
            {
                this.setShowWarning(data);
            }
        })
    }
}
