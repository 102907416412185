
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from '../shared/http.service';
@Component({
  selector: 'app-login',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent
{
    public loginForm: FormGroup;
    public inRequest: boolean = false;
    public showFeedback: boolean = false;
    public feedbackError: boolean = false;

    constructor(private router: Router, private httpService: HttpService)
    {
        this.loginForm = new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.email]),
        });
    }

    public closeRegisterModal(): void
    {
        this.router.navigate(['']);
    }

    public isLoginFormValid(): boolean
    {
        return this.loginForm.valid;
    }

    public stopFormPropagation(event: Event): void
    {
        event.stopPropagation();
    }

    public validateFeedbackClose(): void
    {
        this.showFeedback = false;
        this.feedbackError = false;
        if (!this.feedbackError)
        {
            this.closeRegisterModal();
        }
    }

    public submitLogin(): void
    {
        if (this.isLoginFormValid())
        {
            const loginData: object =
            {
                email: this.loginForm.value.email,
            }

            this.inRequest = true;
            this.showFeedback = false;
            this.httpService.recoverPassword(loginData).subscribe((data =>
            {
                this.showFeedback = true;
                this.feedbackError = data['error'];
                this.inRequest = false;
            }), ()=>
            {
                this.showFeedback = true;
                this.feedbackError = true;
                this.inRequest = false;
            });
        }
    }
}


