
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from '../shared/http.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent
{
    public loginForm: FormGroup;
    public inRequest: boolean = false;
    public showFeedback: boolean = false;
    public feedbackError: boolean = false;
    public passwordType: string = 'password';

    constructor(private router: Router, private httpService: HttpService)
    {
        this.loginForm = new FormGroup({
            password: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, Validators.email]),
        });
    }


    public togglePasswordType(): void
    {
        this.passwordType= this.passwordType == 'password' ? 'text' : 'password';
    }

    public isShowingPassword(): boolean
    {
        return this.passwordType == 'text';
    }

    public closeRegisterModal(): void
    {
        this.router.navigate(['']);
    }

    public isLoginFormValid(): boolean
    {
        return this.loginForm.valid;
    }

    public stopFormPropagation(event: Event): void
    {
        event.stopPropagation();
    }

    public validateFeedbackClose(): void
    {
        this.showFeedback = false;
        this.feedbackError = false;
        if (!this.feedbackError)
        {
            this.closeRegisterModal();
        }
    }

    public submitLogin(): void
    {
        if (this.isLoginFormValid())
        {
            const loginData: object =
            {
                email: this.loginForm.value.email,
                password: this.httpService.encryptPassword(this.loginForm.value.password),
            }

            this.inRequest = true;
            this.showFeedback = false;
            this.httpService.login(loginData).subscribe((data =>
            {
                this.showFeedback = true;
                this.feedbackError = data['error'];
                this.inRequest = false;
            }), ()=>
            {
                this.showFeedback = true;
                this.feedbackError = true;
                this.inRequest = false;
            });
        }
    }
}


