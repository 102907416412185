import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from '../core/base/base.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class HttpService
{
    constructor(private baseService: BaseService){}

    public getCompanies(): Observable<object>
    {
        return this.baseService.get('companies');
    }

    public register(formData: FormData): Observable<object>
    {
        return this.baseService.postFile('company', formData);
    }

    public updateProfile(formData: FormData): Observable<object>
    {
        return this.baseService.postFile('update-company', formData);
    }

    public login(loginData: object): Observable<object>
    {
        return this.baseService.post('login', loginData);
    }

    public logout(): Observable<boolean>
    {
        return this.baseService.delete('logout');
    }

    public isUserLogged(): Observable<boolean>
    {
        return this.baseService.get('logged');
    }

    public recoverPassword(body: object): Observable<boolean>
    {
        return this.baseService.post('forgotPassword', body);
    }

    public getPolicy(): Observable<boolean>
    {
        return this.baseService.get('policy');
    }

    public getTerms(): Observable<boolean>
    {
        return this.baseService.get('terms');
    }

    public getAccountData(companyId: number = null): Observable<object>
    {
        if (companyId)
        {
            return this.baseService.get(`getCompanyData?companyId=${companyId}`);
        }
        return this.baseService.get('getCompanyData');
    }

    public getCompanytData(companyId: number = null): Observable<object>
    {
        return this.baseService.get(`getCompanyData?companyId=${companyId}`);
    }

    public sendMessage(body: object): Observable<object>
    {
        return this.baseService.post('sendMessage', body);
    }
    

    public encryptPassword(password: string): object
    {
        const passphrase = environment.passphrase;
        const plainText = password;
        const salt = CryptoJS.lib.WordArray.random(256);
        const ivText = CryptoJS.lib.WordArray.random(16);
        const key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });
        const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: ivText });
        const data: object =
        {
            password: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
            salt: CryptoJS.enc.Hex.stringify(salt),
            iv: CryptoJS.enc.Hex.stringify(ivText)
        };

        return data;
    }
}