import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent
{

    public actualYear: number = 2019;
    constructor() {
        const date: Date = new Date();
        this.actualYear = date.getFullYear();
    }

}
