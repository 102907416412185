import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { InterceptedHttp } from './core/intercepted';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RegisterComponent } from './register/register.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { CompanyComponent } from './company/company.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader
{
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations:
    [
        AppComponent,
        HomeComponent,
        FooterComponent,
        HeaderComponent,
        RegisterComponent,
        LoginComponent,
        AccountComponent,
        CompanyComponent,
        ForgotPasswordComponent,
        FeedbackComponent,
        PrivacyComponent,
        TermsComponent,
        ContactUsComponent
    ],
    imports:
    [
        BrowserModule,
        SharedModule,
        HttpClientModule,
        RoutingModule,
        CoreModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule.forRoot({
            loader:
            {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule
    ],
    providers:
    [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptedHttp,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
