import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class InterceptedHttp implements HttpInterceptor
{
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any>
    {
        const token: string = 'TOKEN TEST';
        const headers: {Accept: string, Authorization: string} =
        {
            Accept: 'security/json, application/json',
            Authorization: `JWT ${token}`
        };

        request = request.clone({
            url: request.url,
            setHeaders: headers
        });

        return next.handle(request);
    }
}
