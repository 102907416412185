import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

@Injectable()
export class FirebaseService
{
    private firebase: firebase.analytics.Analytics;
    constructor() {
        // Analytics
        const firebaseConfig: object =
        {
            apiKey: 'AIzaSyBVrHbxD3eHxoQuyTL8sihOR0mXC52KUIU',
            authDomain: 'astropreneurs-9386a.firebaseapp.com',
            databaseURL: 'https://astropreneurs-9386a.firebaseio.com',
            projectId: 'astropreneurs-9386a',
            storageBucket: 'astropreneurs-9386a.appspot.com',
            messagingSenderId: '77007790708',
            appId: '1:77007790708:web:8963e600d97896d6e52721',
            measurementId: 'G-XS19ZTPXDS'
        };
        const register: firebase.app.App  = firebase.initializeApp(firebaseConfig);
        this.firebase = register.analytics();
        this.firebase.setAnalyticsCollectionEnabled(true);
    }

    public logEvent(event: string, data: object = {}): void
    {
        this.firebase.logEvent(event, data);
    }
}
