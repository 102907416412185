import { Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';

@Component({
    selector: 'app-custom-multiple-textarea',
    templateUrl: './custom-multiple-text-area.component.html',
    styleUrls: ['./custom-multiple-text-area.component.scss']
})
export class CustomMultipleTextAreaComponent implements OnChanges
{
	@Input() public label: string = '';
	@Input() public contents: string[] = [''];
	public contentDates: string[] = [''];
	public openedOptions: number[] = [];
	@Input() placeholder: string = '';
	@Output() public onContentChange: EventEmitter<String[]> = new EventEmitter;
	@Input() public isInput: boolean = false;
	@Input() public haveDate: boolean = false;

	public addField(): void
	{
		this.contents.push('');
		this.contentDates.push('');
	}

	public ngOnChanges(): void
	{
		if (this.haveDate) 
		{
			this.setDateFromContent();
		}
	}

	private setDateFromContent(): void
	{
		const contents: string[] = [''];
		this.contents.forEach((content: string, index: number) =>
		{
			let contentDate: string = content.slice(0, 10);
			if (Date.parse(contentDate) > 0)
			{
				this.contentDates[index] = contentDate;
				contents[index] = content.slice(13);
			}
		});
		this.contents = contents;
	}

	public getDateFromDateContents(index: number): FormControl
	{
		return new FormControl(new Date(this.contentDates[index]));
	}

	public removeField(index: number): void
	{
		if (index == 0)
		{
			this.contents[index] = '';
			this.contentDates[index] = '';
		}
		else
		{
			this.contents.splice(index, 1);
			this.contentDates.splice(index, 1);
		}
		this.emitContent();
	}

	public emitContent(): void
	{
		if (this.haveDate)
		{
			const contents: string[] = this.contents.map((content: string, index: number) =>
			{
				return Date.parse(this.contentDates[index]) > 0 ? `${this.contentDates[index]} | ${content}` : content;
			});
			
			this.onContentChange.emit(contents);
		}
		else
		{
			this.onContentChange.emit(this.contents);
		}
		
	}

	public canShowRemoveOption(index: number): boolean
	{
		return index > 0 || this.contents[index].length > 0 || this.contentDates[index].length > 0;
	}

	public saveDate(event: MatDatepickerInputEvent<Date>, index: number): void
	{
		const monthValue: number = event.value['_d'].getMonth();
		const monthStr: string = monthValue < 9 ? `0${monthValue + 1}` : `${monthValue + 1}`;
		const dayValue: number = event.value['_d'].getDate();
		const dayStr: string = dayValue < 10 ? `0${dayValue}` : `${dayValue}`;

		const contentDate: string = `${event.value['_d'].getFullYear()}/${monthStr}/${dayStr}`;
		this.contentDates[index] = contentDate;
		this.emitContent();
	}

	public toggleField(index: number): void
	{
		if (this.openedOptions.includes(index))
		{
			this.openedOptions = this.openedOptions.filter((option: number) =>
			{
				return option != index;
			});
		}
		else
		{
			this.openedOptions.push(index);
		}
	}

	public isOptionOpened(index: number): boolean
	{
		return this.openedOptions.includes(index);
	}

	public trackByIndex(index: number): number
	{
		return index;
	}
}
