import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BaseService } from './base/base.service';
import { AuthService } from './auth/auth.service';

@NgModule({
    imports:
    [
        CommonModule,
        HttpClientModule
    ],
    declarations: [],
    providers:
    [
        AuthService,
        BaseService
    ]
})
export class CoreModule {}
