import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BaseService
{
    private requestConfig: object =
    {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
        withCredentials: true,
        body: null
    };

    private fileRequestConfig: object =
    {
        withCredentials: true,
        body: null
    };

    private readonly apiUrl: string = environment.apiURL;

    public constructor(private readonly httpClient: HttpClient) {}

    public post(url: string, body: any): Observable<any>
    {
        return this.httpClient.post(`${this.apiUrl}${url}`, body, this.requestConfig);
    }

    public postFile(url: string, body: any): Observable<any>
    {
        return this.httpClient.post(`${this.apiUrl}${url}`, body, this.fileRequestConfig);
    }

    public get(url: string): Observable<any>
    {
        return this.httpClient.get(`${this.apiUrl}${url}`, this.requestConfig);
    }

    public put(url: string, body: any): Observable<any>
    {
        return this.httpClient.put(`${this.apiUrl}${url}`, body);
    }

    public delete(url: string): Observable<any>
    {
        return this.httpClient.delete(`${this.apiUrl}${url}`, this.requestConfig);
    }
}
