import { UserCoordinates } from './user-coordinates';
import { UserGender } from './user-gender.enum';

export class User
{
    private _id: number;
    private _name: string;
    private _email: string;
    private _token: string;
    private _age: number;
    private _gender: UserGender;
    private _location: UserCoordinates;

    public constructor(
        id: number,
        name: string,
        email: string,
        token: string,
        age: number,
        gender: UserGender,
        location: UserCoordinates
    )
    {
        this._id = id;
        this._name = name;
        this._email = email;
        this._token = token;
        this._age = age;
        this._gender = gender;
        this._location = location;
    }

    public get id(): number
    {
        return this._id;
    }

    public get name(): string
    {
        return this._name;
    }

    public get email(): string
    {
        return this._email;
    }

    public get token(): string
    {
        return this._token;
    }

    public get age(): number
    {
        return this._age;
    }

    public get gender(): UserGender
    {
        return this._gender;
    }

    public get location(): UserCoordinates
    {
        return this._location;
    }

    public static fromJSON(userJSON: any): User
    {
        return new User(
            userJSON.id,
            userJSON.name,
            userJSON.email,
            userJSON.token,
            userJSON.age,
            userJSON.gender,
            userJSON.location
        );
    }
}
