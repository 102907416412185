import { Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { FilterService } from '../shared/filter.service';
import { HttpService } from '../shared/http.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy
{
    private allCards: object[] = [];
    public cardsToShow: object[] = [];
    public cardGroup: number[] = [];
    public proposedMatch: number = 0;
    public isFirsRowActive: boolean = false;
    public showLoading: boolean = true;
    public showCarousel: boolean = true;
    public hideCarousel: boolean = false;
    public filtering: boolean = false;
    private filterCardNameSubscription: Subscription;
    private filterCardSubscription: Subscription;
    private filteredName: string = '';
    private filteredCardParameters: object;
    private nameCards: object[] = [];
    private parameterCards: object[] = [];
    private filteringName: boolean = false;
    private filteringParameters: boolean = false;
    @ViewChild('homeContainer', {static: false}) public homeContainer: ElementRef;

    constructor(private filterService: FilterService, private httpService: HttpService, private router: Router, private sanitizer: DomSanitizer)
    {
        this.router.events.subscribe((data: RouterEvent) =>
        {
            if (data instanceof NavigationEnd && (this.router.url == '/' || this.router.url == '/home'))
            {
                this.getCompanies();
            }
        });

        this.filterCardNameSubscription = this.filterService.filteredCardName.subscribe((name: string) =>
        {
            this.filterCompanyName(name);
            this.filteredName = name;
        });

        this.filterCardSubscription = this.filterService.filteredCardParameters.subscribe((parametersObject: object) =>
        {
            this.filterCompanyParameters(parametersObject);
            this.filteredCardParameters = parametersObject;
        });
    }

    private filterCompanyName(name: string): void
    {
        this.hideCarousel = true;
        const cardsToShow: object[] = this.allCards.filter((card: object) =>
        {
            return card['name'].toLowerCase().includes(name);
        });
        this.nameCards = cardsToShow;
        this.mergeNameAndParametersFilter(cardsToShow, this.parameterCards);
        this.proposedMatch = this.cardsToShow.length;
        this.setCards();
        setTimeout(() =>
        {
            this.showCarousel = false;
            setTimeout(() => {
                this.showCarousel = true;
            }, 1);
            
            this.hideCarousel = false;
        }, 100);
        this.filtering = name !== '';
        this.filteringName = name !== '';
    }

    private mergeNameAndParametersFilter(nameCards: object[], parametersCards: object[]): void
    {
        let cardsToReturn: object[] = [];
        if (nameCards.length > 0 && parametersCards.length > 0)
        {
            nameCards.forEach((card: object) =>
            {
                parametersCards.forEach((parameterCard: object) =>
                {
                    if (JSON.stringify(card) == JSON.stringify(parameterCard))
                    {
                        cardsToReturn.push(card);
                    }
                });
            });
        }
        else if (nameCards.length > 0 && !this.filteringParameters)
        {
            cardsToReturn = nameCards;
        }
        else if (parametersCards.length > 0 && !this.filteringName)
        {
            cardsToReturn = parametersCards;
        }
        else
        {
            this.cardsToShow = this.allCards;
        }

        this.cardsToShow = cardsToReturn;
    }

    private filterCompanyParameters(parametersObject: object): void
    {
        if (parametersObject)
        {
            this.hideCarousel = true;
            const parametersHasValues: boolean = Object.keys(parametersObject).some((key: string) =>
            {
                return parametersObject[key].length > 0;
            });

            this.filtering = parametersHasValues;

            this.filteringParameters = parametersHasValues;
            
            if (parametersHasValues)
            {
                const parametersHasCountries: boolean = Object.keys(parametersObject).some((key: string) =>
                {
                    return key === 'country' && parametersObject[key].length > 0;
                });

                let parametersCopy: object = JSON.parse(JSON.stringify(parametersObject));
                parametersCopy['country'] = [];

                let cardsToShow: object[] = this.allCards.filter((card: object) =>
                {
                    return Object.keys(parametersCopy).some((key: string) =>
                    {
                        return parametersCopy[key].some((value: string) =>
                        {
                            if (key == 'industry_tags' && card[key] && card[key].toLowerCase().includes(';'))
                            {
                                let cardArr: string[] = card[key].toLowerCase().split(';');

                                return cardArr.includes(value.toLowerCase());
                            }

                            return (card[key] && card[key].toLowerCase() == value.toLowerCase());
                        });
                    });
                });
                this.parameterCards = cardsToShow;
                
                if (parametersHasCountries)
                {
                    let filtered: object[] = [];

                    const parametersCopyHasValues: boolean = Object.keys(parametersCopy).some((key: string) =>
                    {
                        return parametersCopy[key].length > 0;
                    });

                    if (!parametersCopyHasValues)
                    {
                        cardsToShow = this.allCards;
                    }

                    cardsToShow.forEach((card: object) =>
                    {
                        let hasCountry: boolean = parametersObject['country'].some((value: string) =>
                        {
                            return card['country'].toLowerCase() === value.toLowerCase();
                        });
                        
                        if (hasCountry)
                        {
                            filtered.push(card);
                        }
                    });

                    this.parameterCards = filtered;
                    this.mergeNameAndParametersFilter(this.nameCards, filtered);
                }
                else
                {
                    this.mergeNameAndParametersFilter(this.nameCards, cardsToShow);
                }
            }
            else
            {
                this.parameterCards = this.allCards;
                this.mergeNameAndParametersFilter(this.nameCards, this.parameterCards);
            }
            this.setCards();
            this.proposedMatch = this.cardsToShow.length;
            setTimeout(() =>
            {
                this.showCarousel = false;
                setTimeout(() => {
                    this.showCarousel = true;
                }, 1);
                
                this.hideCarousel = false;
            }, 100);
        }
    }

    private getCompanies(): void
    {
        this.httpService.getCompanies().subscribe((data: object) =>
        {
            this.showLoading = false;
            if (data['data'])
            {
                this.allCards = data['data'];
                this.cardsToShow = this.allCards;
                this.setCards();
                this.proposedMatch = this.cardsToShow.length;
                this.filterCompanyName(this.filteredName);
                this.filterCompanyParameters(this.filteredCardParameters);
            }
        });
    }

    public goToLink(url: string): void
    {
        if (!url.includes('http'))
        {
            url = 'http://' + url;
        }
        window.open(url, "_blank");
    }

    public ngOnDestroy(): void
    {
        if (this.filterCardNameSubscription)
        {
            this.filterCardNameSubscription.unsubscribe();
        }
        if (this.filterCardSubscription)
        {
            this.filterCardSubscription.unsubscribe();
        }
    }

    public getCardValue(value: string, addComma: boolean = false): string
    {
        const returnValue: string = value ? value : '';

        return addComma && returnValue !== '' ? `${returnValue}, ` : returnValue ;
    }

    public setCards(): void
    {
        this.cardGroup = [];
        for (let i: number = 0; i < Math.ceil(this.cardsToShow.length / 12); i++)
        {
            this.cardGroup.push(1);
        }
    }

    public filterCardName(event: object): void
    {
        this.filterService.filteredCardName.next((event['target'].value.toLowerCase()));
    }

    public toogleFilters(): void
    {
        this.filterService.toggleFilters.next(2);
    }

    public getSliderTopPosition(): string | SafeStyle
    {
        if (this.homeContainer && this.homeContainer.nativeElement)
        {
            if (this.homeContainer.nativeElement.getBoundingClientRect().bottom <= window.innerHeight)
            {
                const difference: number = window.innerHeight - this.homeContainer.nativeElement.getBoundingClientRect().bottom;
                return this.sanitizer.bypassSecurityTrustStyle(`calc(50% - ${difference}px)`);
            }
            else if (this.homeContainer.nativeElement.getBoundingClientRect().top > 0)
            {
                return this.sanitizer.bypassSecurityTrustStyle(`calc(50% + ${this.homeContainer.nativeElement.getBoundingClientRect().top}px)`);
            }
        }

        return '50%';
    }

    public showCarouselControls(): boolean
    {
       return this.cardGroup && this.cardGroup.length > 0; 
    }
}
