import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/http.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit
{
    public title: string = '';
    public content: string = '';
    public inRequest: boolean = false;

    constructor(private httpService: HttpService)
    {}

    public ngOnInit(): void
    {
        this.inRequest = true;
        this.httpService.getPolicy().subscribe((data) =>
        {
            if (data)
            {
                this.title = data['title'],
                this.content = data['content']
            }
            this.inRequest = false;
        })
    }
}
