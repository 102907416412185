import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterService } from './filter.service';
import { HttpService } from './http.service';
import { FirebaseService } from './firebase.service';
import { CustomSelectComponent } from '../custom-select/custom-select.component';
import { CustomMultipleTextAreaComponent } from '../custom-multiple-text-area/custom-multiple-text-area.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { CustomCardComponent } from '../custom-card/custom-card.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS =
{
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};


@NgModule({
    declarations: [
        CustomSelectComponent,
        CustomMultipleTextAreaComponent,
        CustomCardComponent
    ],
    imports:
    [
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule
    ],
    exports:
    [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        CustomSelectComponent,
        CustomMultipleTextAreaComponent,
        CustomCardComponent
    ],
    providers:
    [
        FilterService,
        HttpService,
        FirebaseService,
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class SharedModule {}
