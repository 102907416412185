import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/http.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit
{
    public title: string = '';
    public content: string = '';
    public inRequest: boolean = false;

    constructor(private httpService: HttpService)
    {}

    public ngOnInit(): void
    {
        this.inRequest = true;
        this.httpService.getTerms().subscribe((data) =>
        {
            if (data)
            {
                this.title = data['title'],
                this.content = data['content']
            }
            this.inRequest = false;
        })
    }
}
