import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { FilterService } from '../shared/filter.service';
import { HttpService } from '../shared/http.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent
{
    public showFeedback: boolean = false;
    public feedbackError: boolean = false;
    public showLoading: boolean = true;
    public showIndustryOptions: boolean = false;
    public showTypeOptions: boolean = false;
    public accountData: FormGroup;
    public industryTags: string[];
    public companyTypes: string[];
    private file: string;
    private formData: FormData;
    public inRequest: boolean = false;
    public passwordTypes: string[] = ['password', 'password', 'password'];
    public passwordError: boolean = false;
    public userData: object = {
        name: null,
        address: null,
        country: null,
        phone: null,
        website: null,
        company_email: null,
        slogan: null,
        industry_tags: null,
        type: null,
        logo: null,
        technology_challenges: [''],
        technology_offers: [''],
        open_call_for_collaboration: [''],
        self_organized_events: [''],
        job_offers: [''],
        social_media: ['']
    };
    public filename: string = '';
    private triedSubmission: boolean = false;
    public newPassword: string;
    public repeatNewPassword: string;
    private selectedIndustries: string[];
    private arrayCompanyOption: string[] = ['technology_challenges', 'technology_offers', 'open_call_for_collaboration', 'self_organized_events', 'job_offers', 'social_media'];

    public constructor(private router: Router, private httpService: HttpService, private translateService: TranslateService, private filteService: FilterService)
    {
        this.accountData = new FormGroup({
            company: new FormControl(null, Validators.required),
            location: new FormControl(null, Validators.required),
            country: new FormControl(null, Validators.required),
            phone: new FormControl(null, Validators.required),
            webLink: new FormControl(null),
            email: new FormControl(null, [Validators.required, Validators.email]),
            businessSlogan: new FormControl(null),
            industry: new FormControl(null, Validators.required),
            type: new FormControl(null, Validators.required),
            oldPassword: new FormControl(null),
            newPassword: new FormControl(null),
            repeatNewPassword: new FormControl(null)
        });

        this.httpService.isUserLogged().subscribe((data: boolean) =>
        {
            if (!data)
            {
                this.router.navigate(['']);
            }
            else
            {
                const industryStream: Observable<string[]> = this.translateService.get('FILTERS.INDUSTRY_OPTIONS');
                const typesStream: Observable<string[]> = this.translateService.get('FILTERS.TYPE_OPTIONS');
                forkJoin([industryStream, typesStream]).subscribe((results: string[][]) =>
                {
                    this.industryTags = results[0];
                    this.industryTags.sort();
                    this.companyTypes = results[1];
                    this.getUserData();
                });
            }
        })
    }

    public setMuiltpleTextAreaValue(values: string[], key: string)
    {
        this.userData[key] = values;
    }

    public logout(): void
    {
        this.httpService.logout().subscribe(data =>
        {
            this.router.navigate(['']);
        });
    }

    public validateNumber(event: any): void
    {
        const pattern = /[0-9\+\-\ ]/;
    
        let inputChar: string = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar))
        {
            event.preventDefault();
        }
    }

    public isPasswordInvalid(): boolean
    {
        return this.accountData.value.newPassword != this.accountData.value.repeatNewPassword;
    }

    public isOptionInvalid(option: string): boolean
    {
        return this.accountData.get(option) ? (this.triedSubmission && this.accountData.get(option).invalid) : false;
    }

    public togglePasswordTypes(index: number): void
    {
        this.passwordTypes[index] = this.passwordTypes[index] == 'password' ? 'text' : 'password';
    }

    public isShowingPassword(index: number): boolean
    {
        return this.passwordTypes[index] == 'text';
    }

    public stopFormPropagation(event: Event): void
    {
        event.stopPropagation();
    }

    public hideAllOptions(): void
    {
        this.showIndustryOptions = false;
        this.showTypeOptions = false;
    }

    public onFileSelected(event: object):  void
    {
        if ((event['target'].files[0]))
        {
            this.file = event['target'].files[0];
            this.filename = this.file['name'];
            const reader = new FileReader();
            reader.readAsDataURL(event['target'].files[0]); 
            reader.onload = () => { 
                this.userData['logo'] = reader.result; 
            }
        }
        else
        {
            this.file = null;
        }
    }

    public isProfileFormValid(): boolean
    {
        return this.accountData.valid && this.isPasswordEqual();
    }
    
    public selectType(value: string)
    {
        this.accountData.controls['type'].setValue(value)
    }

    public selectIndustry(value: string[])
    {
        this.selectedIndustries = value;
        if (value && value.length > 0)
        {
            this.accountData.controls['industry'].setValue(value[0])
        }
    }

    private appendCompanyVaues(values: string[][]): void
    {
        values.forEach((value: string[]) =>
        {
            this.formData.append(value[0], value[1] ? value[1] : '');
        });
    }

    public  tokenizeOption(option: string): string
    {
        let tokenized: string =  option;
        tokenized = tokenized.toLowerCase();
        tokenized = tokenized.replace(/,/g, "");
        tokenized = tokenized.replace(/ /g, "_");
        tokenized = tokenized.replace(/-/g, "");

        return tokenized;
    }

    private isPasswordEqual(): boolean
    {
        return this.newPassword === this.repeatNewPassword;
    }

    public updateUserData(): void
    {
        this.triedSubmission = true;
        if (this.isProfileFormValid())
        {
            let industry: string = '';

            this.selectedIndustries.forEach((selectedIndustry: string, index: number) =>
            {
                let tokenizedValue: string = ['Construction', 'Financial services'].includes(selectedIndustry) ? this.tokenizeOption(selectedIndustry).toUpperCase() : this.tokenizeOption(selectedIndustry);
                industry += `${index > 0 ? ';' : ''}${tokenizedValue}`;
            });

            let type: string = this.tokenizeOption(this.accountData.value.type);

            let oldPassword: object = this.httpService.encryptPassword(this.accountData.value.oldPassword);

            let newPassword: object = this.httpService.encryptPassword(this.accountData.value.newPassword);

            this.formData = new FormData();
            this.appendCompanyVaues([['logo', this.file], ['name', this.accountData.value.company], ['address', this.accountData.value.location], ['country', this.accountData.value.country],
            ['phone', this.accountData.value.phone], ['company_email', this.accountData.value.email], ['website', this.accountData.value.webLink],
            ['slogan', this.accountData.value.businessSlogan], ['industry_tags', industry], ['type',  type], ['technology_challenges', this.accountData.value.technologyChallenges],
            ['technology_offers', this.accountData.value.technologyOffers], ['open_call_for_collaboration', this.accountData.value.openCall], ['self_organized_events', this.accountData.value.selfEvents],
            ['job_offers', this.accountData.value.jobOffers], ['social_media', this.accountData.value.socialMedia]]);
            const arrayValuesToAppend: string[][] = [];
            this.arrayCompanyOption.forEach((companyOption: string) =>
            {
                if (this.userData[companyOption])
                {
                    let appendedOption: string = '';
                    this.userData[companyOption].forEach((option: string, index: number) =>
                    {
                        option = option.replace(/[\n\r]/g, ' ');
                        if (option != '')
                        {
                            appendedOption +=  `${index > 0 ? ';\n' : ''}${option}`;
                        }
                    });
                    arrayValuesToAppend.push([`${companyOption}`, appendedOption]);
                }
            });

            this.appendCompanyVaues(arrayValuesToAppend);

            if (this.accountData.value.oldPassword && this.accountData.value.oldPassword.length > 0)
            {
                this.appendCompanyVaues([['oldPassword', oldPassword['password']],
                ['oldPasswordSalt', oldPassword['salt']],  ['oldPasswordIv', oldPassword['iv']],['newPassword', newPassword['password']], ['newPasswordSalt', newPassword['salt']],
                ['newPasswordIv', newPassword['iv']]]);
            }

            this.inRequest = true;
            this.showFeedback = false;
            this.passwordError = false;
            this.httpService.updateProfile(this.formData).subscribe((data =>
            {
                window.scroll(0, 0);
                this.showFeedback = true;
                this.feedbackError = data['error'] || data['passwordError'];
                this.passwordError = data['passwordError'];
                this.inRequest = false;
                if (!data['error'])
                {
                    this.filteService.profileUpdated.next(data['data']);
                }
            }), ()=>
            {
                window.scroll(0, 0);
                this.feedbackError = true;
                this.inRequest = false;
            });
        }
    }

    private getOrginalTokenizedValue(tokenized: string, valuesArr: string[]): string
    {
        const correctValue: string = valuesArr.find((value: string) =>
        {
            return this.tokenizeOption(value) == tokenized.toLowerCase();
        })

        return correctValue ? correctValue : tokenized;
    }

    private setMultipleTextAreaData(userData: object): object
    {
        this.arrayCompanyOption.forEach((companyOption: string) =>
        {
            if (userData[companyOption])
            {
                let splitedData: string[] = userData[companyOption].split(';\n');
                userData[companyOption] = splitedData.length > 1 ? splitedData : [userData[companyOption]];
            }
            else
            {
                userData[companyOption] = [''];
            }
        });

        return userData;
    }

    public getUserData(): void
    {
        this.httpService.getAccountData().subscribe((data: object) =>
        {
            if (!data)
            {
                this.router.navigate(['']);
            }
            else
            {
                if (data['industry_tags'])
                {
                    data['industry_tags'] = data['industry_tags'].includes(';') ? data['industry_tags'].split(';') : [data['industry_tags']];
                    const industryTags: string[] = [];
                    data['industry_tags'].forEach((industry: string) =>
                    {
                        industryTags.push(this.getOrginalTokenizedValue(industry, this.industryTags));
                    });
                    data['industry_tags'] = industryTags;
                    this.selectIndustry(data['industry_tags']);
                }

                if (data['type'])
                {
                    data['type'] = this.getOrginalTokenizedValue(data['type'], this.companyTypes);
                    this.selectType(data['type']);
                }

                const userData: object = data;

                this.userData = this.setMultipleTextAreaData(userData);
                this.showLoading = false;
            }
        })
    }
}
