import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnChanges
{
    @Input() public availableOptions: string[] = [];
    @Input() public invalid: boolean = false;
    @Input() public selectedOption: string;
    @Input() public background: string = 'white'; 
    public showOptions: boolean = false;
    @Output() public onSelectOption: EventEmitter<string> = new EventEmitter;
    @Output() public onSelectOptions: EventEmitter<string[]> = new EventEmitter;
    @Input() public multiple: boolean = false;
    @Input() public selectedOptions: string[] = [];

    public ngOnChanges(): void
    {
        if (this.multiple)
        {
            this.buildSelectedOption();
        }

        if (!this.selectedOptions)
        {
            this.selectedOptions = [];
        }
    }

    public selectOption(option: string): void
    {
        if (this.multiple)
        {
            const selectedOption: string = option;
            if (this.selectedOptions && this.selectedOptions.includes(selectedOption))
            {
                this.selectedOptions = this.selectedOptions.filter((option: string) =>
                {
                    return option != selectedOption;
                })
            }
            else
            {
                this.selectedOptions.push(selectedOption);
            }
            this.buildSelectedOption();
            this.onSelectOptions.emit(this.selectedOptions);
        }
        else
        {
            this.selectedOption = option;
            this.onSelectOption.emit(option);
            this.closeOptions();
        }
    }

    private buildSelectedOption(): void
    {
        this.selectedOption = '';
        if (this.selectedOptions)
        {
            this.selectedOptions.forEach((selectedOption: string, index: number) =>
            {
                this.selectedOption += `${index > 0 ? ', ' : ''} ${selectedOption}`;
            });
        }
    }

    public isOptionSelected(option: string): boolean
    {
        return this.selectedOption == option || this.selectedOptions && this.selectedOptions.includes(option);
    }

    public openOptions(): void
    {
        this.showOptions = true;
    }

    public closeOptions(): void
    {
        this.showOptions = false;
    }
}
