
import { AfterViewInit, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from '../shared/http.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements AfterViewInit
{
    public registerForm: FormGroup;
    public inRequest: boolean = false;
    private file: string;
    private formData: FormData;
    public industryTags: string[];
    public companyTypes: string[];
    public showFeedback: boolean = false;
    public feedbackError: boolean = false;
    public password: string;
    public confirmPassword: string;
    public filename: string = '';
    public triedSubmission: boolean = false;
    public acceptedPolicy: boolean = false;
    private selectedIndustries: string[];
    public emailExist: boolean = false;

    public passwordTypes: string[] = ['password', 'password'];

    constructor(private router: Router, private httpService: HttpService, private translateService: TranslateService)
    {
        this.registerForm = new FormGroup({
            company: new FormControl(null, Validators.required),
            location: new FormControl(null, Validators.required),
            country: new FormControl(null, Validators.required),
            phone: new FormControl(null, Validators.required),
            webLink: new FormControl(null),
            email: new FormControl(null, [Validators.required, Validators.email]),
            businessSlogan: new FormControl(null),
            industry: new FormControl(null, Validators.required),
            type: new FormControl(null, Validators.required),
            password: new FormControl(null),
            confirmPassword: new FormControl(null)
        });
    }

    public ngAfterViewInit(): void
    {
        this.translateService.stream('FILTERS.INDUSTRY_OPTIONS').subscribe(data =>
        {
            this.industryTags = data;
            this.industryTags.sort();
        });
        this.translateService.stream('FILTERS.TYPE_OPTIONS').subscribe(data =>
        {
            this.companyTypes = data;
        });
    }

    public togglePasswordTypes(index: number): void
    {
        this.passwordTypes[index] = this.passwordTypes[index] == 'password' ? 'text' : 'password';
    }

    public toggleAcceptPolicy(): void
    {
        this.acceptedPolicy = !this.acceptedPolicy;
    }

    public isShowingPassword(index: number): boolean
    {
        return this.passwordTypes[index] == 'text';
    }

    public isPasswordInvalid(): boolean
    {
        return this.registerForm.value.password != this.registerForm.value.confirmPassword;
    }

    public closeRegisterModal(): void
    {
        this.router.navigate(['']);
    }

    public stopFormPropagation(event: Event): void
    {
        event.stopPropagation();
    }

    public isOptionInvalid(option: string): boolean
    {
        return this.registerForm.get(option) ? (this.triedSubmission && this.registerForm.get(option).invalid) : false;
    }

    public onFileSelected(event: object):  void
    {
        if ((event['target'].files[0]))
        {
            this.file = event['target'].files[0];
            this.filename = this.file['name'];
        }
        else
        {
            this.file = null;
        }
    }

    public isRegistrationFormValid(): boolean
    {
        return this.registerForm.valid;
    }

    public validateNumber(event: any): void
    {
        const pattern = /[0-9\+\-\ ]/;
    
        let inputChar: string = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar))
        {
            event.preventDefault();
        }
    }
    
    public selectType(value: string)
    {
        this.registerForm.controls['type'].setValue(value)
    }

    public selectIndustry(value: string[])
    {
        this.selectedIndustries = value;
        if (value && value.length > 0)
        {
            this.registerForm.controls['industry'].setValue(value[0])
        }
    }

    private appendCompanyVaues(values: string[][]): void
    {
        values.forEach(value =>
        {
            if (value[1])
            {
                this.formData.append(value[0], value[1]);
            }
        });
    }

    public  tokenizeOption(option: string): string
    {
        let tokenized: string =  option;
        tokenized = tokenized.toLowerCase();
        tokenized = tokenized.replace(/,/g, "");
        tokenized = tokenized.replace(/ /g, "_");
        tokenized = tokenized.replace(/-/g, "");

        return tokenized;
    }
    
    public validateFeedbackClose(): void
    {
        this.showFeedback = false;
        this.feedbackError = false;
        this.emailExist = false;
        if (!this.feedbackError)
        {
            this.closeRegisterModal();
        }
    }

    public getFeedbackErrorMessage(): string
    {
        return this.emailExist ? 'REGISTRATION_EMAIL_EXIST' : 'PLEASE_TRY';
    }

    public submitRegistration(): void
    {
        this.triedSubmission = true;
        if (this.isRegistrationFormValid() && this.acceptedPolicy && !this.isPasswordInvalid())
        {
            let industry: string = '';

            this.selectedIndustries.forEach((selectedIndustry: string, index: number) =>
            {
                let tokenizedValue: string = ['Construction', 'Financial services'].includes(selectedIndustry) ? this.tokenizeOption(selectedIndustry).toUpperCase() : this.tokenizeOption(selectedIndustry);
                industry += `${index > 0 ? ';' : ''}${tokenizedValue}`;
            });

            let type: string = this.tokenizeOption(this.registerForm.value.type);

            let password: object = this.httpService.encryptPassword(this.registerForm.value.password);

            this.formData = new FormData();
            this.appendCompanyVaues([['logo', this.file], ['name', this.registerForm.value.company], ['address', this.registerForm.value.location], ['country', this.registerForm.value.country],
            ['phone', this.registerForm.value.phone], ['company_email', this.registerForm.value.email], ['website', this.registerForm.value.webLink],
            ['slogan', this.registerForm.value.businessSlogan], ['industry_tags', industry], ['type',  type], ['acceptedPolicy',  true]]);

            if (this.registerForm.value.password && this.registerForm.value.password.length > 0)
            {
                this.appendCompanyVaues([['password', password['password']], ['salt', password['salt']], ['iv', password['iv']]]);
            }

            this.inRequest = true;
            this.showFeedback = false;

            this.httpService.register(this.formData).subscribe((data =>
            {
                this.showFeedback = true;
                this.feedbackError = !data || data['error'];
                this.emailExist = data['emailExist'];
                this.inRequest = false;
            }), ()=>
            {
                this.showFeedback = true;
                this.feedbackError = true;
                this.inRequest = false;
            });
        }
    }
}


