
import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent
{
    @Input() public title: string = '';
    @Input() public message: string = '';
    @Input() public error: boolean = false;
    @Input() public show: boolean = false;
    @Output() onClose: EventEmitter<void> = new EventEmitter;


    public stopFormPropagation(event: Event): void
    {
        event.stopPropagation();
    }

    public closeFeedBack(): void
    {
        this.show = false;
        this.onClose.emit();
    }
}


