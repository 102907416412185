import { Component, ViewChild, ElementRef, AfterViewInit, HostListener, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FilterService } from '../shared/filter.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit, OnInit
{
    public allCountries: string[] = ['Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czechia', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece',
        'Hungary', 'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain',
        'Sweden', 'United Kingdom'];
    public countries: string[] = [''];
    public showCountries: boolean = false;
    public filterHeight: number = 0;
    public maxFilterHeght: number = 100;
    public showFilters: boolean = false;
    public showOverflow: boolean = false;
    public fixHeader: boolean = false;
    public filterProperties: string[] = ['country', 'type', 'industry_tags', 'specification'];
    private filteredParameters: object = {};
    public filterSwipeWidth: number = 0;
    public showSimpleHeader: boolean = false;
    @Input() public showWarning: boolean = false;
    @Input() public showHeaderOptions: boolean = true;
    @Input() public isUserLogged: boolean = false;
    @ViewChild('filteringContainer', {static: false}) public filterContainer: ElementRef;
    @ViewChild('fixedHeaderElement', {static: false}) public fixedHeaderElement: ElementRef;
    @ViewChild('filteringReference', {static: false}) public filteringReference: ElementRef;
    @ViewChild('allFiltersReference', {static: false}) public allFiltersReference: ElementRef;
    
    constructor(private filterService: FilterService, private router: Router)
    {
        this.countries = this.allCountries;
        this.filterProperties.forEach((property: string) =>
        {
            this.filteredParameters[property] = [];
        });

        this.filterService.toggleFilters.subscribe((data: number) =>
        {
            if (data > 1)
            {
                this.toogleFilters();
            }
        });
    }

    public ngOnInit(): void
    {
        this.router.events.subscribe((data: RouterEvent) =>
        {
            if (data instanceof NavigationEnd)
            {
                this.filterHeight = 0;
                this.showFilters = false;
                this.showOverflow = false;
                this.showSimpleHeader = ['terms-and-conditions', 'account', 'privacy-policy', 'company'].some((company: string) =>
                {
                    return this.router.url.includes(company);
                });
            }
        });
    }

    public stopPropagation(event: Event): void
    {
        event.stopPropagation();
    }

    public getSwipePositon(): string
    {
        if (window.innerWidth > 1199)
        {
            return `translateX(${this.filterSwipeWidth}px)`
        }
        return `translateX(${this.filterSwipeWidth}%)`
    }

    public ngAfterViewInit(): void
    {
        setTimeout(() =>
        {
            this.setMaxFilterHeight();
            if (this.showFilters)
            {
                this.setFilterHeight();
            }
        }, 500);
    }

    @HostListener('window:scroll', [])
    public onWindowScroll(): void
    {
        this.fixHeader = this.fixedHeaderElement.nativeElement.getBoundingClientRect().top < 7 &&
                        this.filteringReference.nativeElement.getBoundingClientRect().top < 7;

        this.setMaxFilterHeight();
    }

    private setMaxFilterHeight(): void
    {
        this.maxFilterHeght = this.fixedHeaderElement.nativeElement.getBoundingClientRect().top + 72;
    }
    
    public toogleFilters(): void
    {
        if (!this.showSimpleHeader)
        {
            if (!this.showFilters)
            {
                this.setFilterHeight();
                this.showFilters = true;
                setTimeout(() =>
                {
                    this.showOverflow = true;
                }, 300);
            }
            else
            {
                this.filterHeight = 0;
                this.showFilters = false;
                this.showOverflow = false;
            }
        }
    }
    
    public filterCountry(event: object): void
    {
        this.showCountries = true;
        this.countries = this.allCountries.filter((country: string) =>
        {
            return country.toLowerCase().includes(event['target'].value);
        });
    }

    public getCorrectLabel(): string
    {
        return window.innerWidth > 1500 ? 'SEARCH_PARTNER' : 'SEARCH';
    }

    public onFilterResize(): void
    {
        if (this.showFilters)
        {
            this.setFilterHeight();
        }
    }

    public swipeFilterOptions(rightSwipe: boolean = true): void
    {
        if (this.canSwipeFilters(rightSwipe))
        {
            if (window.innerWidth > 1199)
            {
                this.filterSwipeWidth = rightSwipe ? (this.filterSwipeWidth - 318) : (this.filterSwipeWidth + 318);
            }
            else
            {
                this.filterSwipeWidth = rightSwipe ? (this.filterSwipeWidth - 25) : (this.filterSwipeWidth + 25);
            }
        }
    }

    public canSwipeFilters(rightSwipe: boolean = true): boolean
    {
        if (rightSwipe)
        {
            return this.allFiltersReference && (window.innerWidth - this.allFiltersReference.nativeElement.getBoundingClientRect().right) < 0;
        }

        return this.allFiltersReference && this.allFiltersReference.nativeElement.getBoundingClientRect().left < 0;
    }

    private setFilterHeight(): void
    {
        if (this.filterContainer)
        {
            this.filterHeight = this.filterContainer.nativeElement['offsetHeight'] + 32;
        }
    }

    public isBulletActive(index: number): boolean
    {
        return index * 25 == -this.filterSwipeWidth;
    }

    public setFilterSwipeValue(index: number):  void
    {
        this.filterSwipeWidth = index * -25;
    }

    public filterCardName(event: object): void
    {
        this.filterService.filteredCardName.next((event['target'].value.toLowerCase()));
    }
    
    public checkIfValueIsFiltered(property: string, value: string, fromFilter: boolean = false): boolean
    {
        if (!fromFilter)
        {
            value = this.setFilterToken(value);
        }

        return this.filteredParameters[property].some((propertyValue: string) =>
        {
            return propertyValue.toLowerCase() === value.toLowerCase();
        });
    }

    private setFilterToken(value: string): string
    {
        value = value.toLowerCase();
        value = value.replace(/[^a-zA-Z ]/g, '');
        value = value.replace(/\s/g, '_');
        value = value.replace('__', '_');

        return value;
    }

    public filterMultipleOptions(property: string, value: string): void
    {
        value = this.setFilterToken(value);
        if (this.checkIfValueIsFiltered(property, value, true))
        {
            this.filteredParameters[property] = this.filteredParameters[property].filter((propertyValue: string) =>
            {
                return propertyValue.toLowerCase() !== value.toLowerCase();
            });
        }
        else
        {
            this.filteredParameters[property].push(value.toLowerCase());
        }

        this.filterService.filteredCardParameters.next(this.filteredParameters);
    }
}
