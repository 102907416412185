import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FilterService
{
    public filteredCardParameters: BehaviorSubject<object> = new BehaviorSubject<object>(null);
    public filteredCardName: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public toggleFilters: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public footerElement: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public profileUpdated: BehaviorSubject<object> = new BehaviorSubject<object>(null); 
}