import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpService } from '../shared/http.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent
{
    public showLoading: boolean = true;
    private allIndustryTags: string[];
    private allCompanyTypes: string[];
    public companyData: object = {
        name: null,
        address: null,
        country: null,
        phone: null,
        website: null,
        company_email: null,
        slogan: null,
        industry_tags: null,
        type: null,
        logo: null,
        technology_challenges: [''],
        technology_offers: [''],
        open_call_for_collaboration: [''],
        self_organized_events: [''],
        job_offers: [''],
        social_media: [''],
    };

    public socialImages: string[] = ['facebook', 'github', 'instagram', 'linkedin', 'medium', 'twitter'];

    private companyId: number = 0;

    public constructor(private route: ActivatedRoute, private router: Router, private httpService: HttpService, private translateService: TranslateService)
    {
        this.companyId = Number(this.route.snapshot.paramMap.get('id'));
        const industryStream: Observable<string[]> = this.translateService.get('FILTERS.INDUSTRY_OPTIONS');
        const typesStream: Observable<string[]> = this.translateService.get('FILTERS.TYPE_OPTIONS');
        forkJoin([industryStream, typesStream]).subscribe((results: string[][]) => {
            this.allIndustryTags = results[0];
            this.allCompanyTypes = results[1];
            this.getCompanyData(this.companyId);
        });
    }
    
    public getSocialIcon(url: string): string
    {
        let possibleOptions = this.socialImages.filter((social: string) =>
        {
            let splited: string[] = social.split('/').filter((socialString: string) =>
            {
                return url.includes(socialString);
            });

            return splited.length > 0 && url.includes(splited[0]);
        });

        return possibleOptions.length > 0 ? `./assets/images/socials/icn-${possibleOptions[0]}.svg` : `./assets/images/socials/icn-default-image.svg`;
    }

    public goToLink(url: string): void
    {
        if (!url.includes('http'))
        {
            url = `http://${url}`;
        }
        window.open(url, "_blank");
    }

    public getCompanyLocation(): string
    {
        return `https://www.google.com/maps/place/${this.getCorrectData(this.companyData['address'], true)}${this.getCorrectData(this.companyData['country'])}`;
    }

    public getCompanyShortLocation(): string
    {
        return `${this.getCorrectData(this.companyData['address'], true)}${this.getCorrectData(this.companyData['country'])}`;
    }

    public getCorrectData(value: string, addComma: boolean = false): string
    {
        const returnValue: string = value ? value : '';

        return addComma && returnValue !== '' ? `${returnValue}, ` : returnValue ;
    }

    public isValueValid(value: string | string[]): boolean
    {
        return value && value.length > 0 && value[0] != '';
    }

    public  tokenizeOption(option: string): string
    {
        let tokenized: string =  option;
        tokenized = tokenized.toLowerCase();
        tokenized = tokenized.replace(/,/g, "");
        tokenized = tokenized.replace(/ /g, "_");
        tokenized = tokenized.replace(/-/g, "");

        return tokenized;
    }

    private getOrginalTokenizedValue(tokenized: string, valuesArr: string[]): string
    {
        const correctValue: string = valuesArr.find((value: string) =>
        {
            return this.tokenizeOption(value) == tokenized.toLowerCase();
        })

        return correctValue ? correctValue : tokenized;
    }

    private setMultipleTextAreaData(userData: object): object
    {
        const arrayCompanyOption: string[] = ['technology_challenges', 'technology_offers', 'open_call_for_collaboration', 'self_organized_events', 'job_offers', 'social_media'];
        arrayCompanyOption.forEach((companyOption: string) =>
        {
            if (userData[companyOption])
            {
                let splitedData: string[] = userData[companyOption].split(';\n');
                userData[companyOption] = splitedData.length > 1 ? splitedData : [userData[companyOption]];
            }
            else
            {
                userData[companyOption] = [''];
            }
        });

        return userData;
    }

    public getCompanyData(companyId: number): void
    {
        this.httpService.getCompanytData(companyId).subscribe((data: object) =>
        {
            
            if (!data)
            {
                this.router.navigate(['']);
            }
            else
            {
                if (data['industry_tags'])
                {
                    data['industry_tags'] = data['industry_tags'].includes(';') ? data['industry_tags'].split(';') : [data['industry_tags']];
                    const industryTags: string[] = [];
                    data['industry_tags'].forEach((industry: string) =>
                    {
                        industryTags.push(this.getOrginalTokenizedValue(industry, this.allIndustryTags));
                    });
                    data['industry_tags'] = industryTags;
                }

                if (data['type'])
                {
                    data['type'] = this.getOrginalTokenizedValue(data['type'], this.allCompanyTypes);
                }

                const companyData: object = data;

                this.companyData = this.setMultipleTextAreaData(companyData);
                this.showLoading = false;
            }
        })
    }
}
