import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { CompanyComponent } from './company/company.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RegisterComponent } from './register/register.component';
import { TermsComponent } from './terms/terms.component';

const routes: Routes =
[
    {
        path: 'home',        
        component: HomeComponent
    },
    {
        path: 'account',        
        component: AccountComponent
    },
    {
        path: 'privacy-policy',        
        component: PrivacyComponent
    },
    /*{
        path: 'terms-and-conditions',        
        component: TermsComponent
    },*/
    {
        path: 'company',        
        component: CompanyComponent
    },
    {
        path: 'company/:id',        
        component: CompanyComponent
    },
    {
        path: '',  
        component: HomeComponent,
        children: [
            {
                path: 'register',
                component: RegisterComponent
            },
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'recover',
                component: ForgotPasswordComponent
            }
        ]
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports:
    [
        RouterModule.forRoot(routes)
    ],
    exports:
    [
        RouterModule
    ]
})
export class RoutingModule {}
