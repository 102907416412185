import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { User } from '../../domain/user/user';
import { map } from 'rxjs/operators';
import { UserGender } from '../../domain/user/user-gender.enum';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService
{
    public user: User;

    public constructor(private baseService: BaseService) {}

    public login(body: {email: string, password: string}): Observable<User>
    {
        return this.baseService.post('user/login', body).pipe(
            map((data: {id: number, name: string, email: string, token: string, age: number, gender: string, coordinates: {lat: number, lon: number}}) =>
            {
                this.user = new User(data.id, data.name, data.email, data.token, data.age, UserGender[data.gender], {lat: data.coordinates.lat, lon: data.coordinates.lon});

                return this.user;
            }));
    }

    public logout(): Observable<void>
    {
        return this.baseService.delete('user/logout');
    }

}
